<template>
  <cw-page
    icon="person"
    class="cw-basic-information"
  >
    <template #hero-title>
      <translate>
        Get your money up to 5 minutes!
      </translate>
    </template>

    <validation-observer
      ref="observer"
      autocomplete="off"
      tag="v-form"
    >
      <v-row>
        <v-col class="basic-information__email--wrapper" cols="12" md="6">
          <cw-text-field
            id="email"
            v-model="form.email"
            :class="{ 'busy': $wait.waiting('email') }"
            :hint="emailHint"
            :label="emailLabel"
            rules="required|email"
            type="text"
            anonymize
          />
        </v-col>
        <v-col class="basic-information__confirmCode--wrapper" cols="12" md="6">
          <cw-text-field
            id="confirmCode"
            v-model="form.confirmCode"
            :class="{ 'busy': $wait.waiting('confirmCode') }"
            :hint="confirmCodeHint"
            :label="confirmCodeLabel"
            rules="required|digits:6"
            type="text"
          />
          <div class="mt-4 text-caption">
            <translate>
              It may take a few minutes for the verification code to be sent.
            </translate>
            <a
              id="basic-information__retryStart"
              @click="
                retryStart();
                $eventLogger.clickEvent($event);
              "
            >
              &nbsp;
              <translate>
                If you do not receive the SMS, please try again.
              </translate>
            </a>
          </div>
        </v-col>
        <v-col class="basic-information__ringData--wrapper" cols="12">
          <cw-checkbox
            id="ringData"
            v-model="form.ringData"
            class="cw-hide-details"
            rules="required"
            hide-details
          >
            <template #label>
              <span data-test>
                <translate>I agree to reviewing customer information</translate>
              </span>
            </template>
          </cw-checkbox>
          <div class="pl-8 text-caption">
            <translate>
              The consumer credit survey system maintained by Suomen Asiakastieto Oy is used for
              checking.
            </translate>
            <a
              id="dataGatheringPermission--link"
              href="#"
              @click.prevent.stop="$bus.$emit('OPEN_CONFIRMATION_DIALOG', {
                component: 'DialogDataGatheringPermission',
                folder: 'fi',
              })"
            >
              <translate>More information</translate>
            </a>
          </div>
        </v-col>
        <v-col class="basic-information__marketingAllowed--wrapper" cols="12">
          <cw-checkbox
            id="marketingAllowed"
            v-model="form.marketingAllowed"
            hide-details
          >
            <template #label>
              <span data-test>
                {{ marketingAllowedLabel }}
              </span>
            </template>
          </cw-checkbox>
          <div class="pl-8 text-caption">
            <translate>
              I want to receive marketing messages and newsletters via SMS and Email.
            </translate>
          </div>
        </v-col>

        <v-col v-if="showPep()" class="basic-information__pep--wrapper" cols="12">
          <cw-pep :form="form"/>
          <div class="pl-8 text-caption">
            <translate>
              I am a politically influential person
            </translate>
          </div>
        </v-col>
      </v-row>
    </validation-observer>

    <template #actions>
      <v-spacer/>
      <v-btn
        id="submit"
        :dark="!$wait.waiting('SUBMIT_FORM')"
        :disabled="$wait.waiting('SUBMIT_FORM')"
        :loading="$wait.waiting('SUBMIT_FORM')"
        color="purple-one darken-2"
        @click="
          validateForm();
          $eventLogger.clickEvent($event);
        "
      >
        <translate>Continue</translate>
      </v-btn>
    </template>
  </cw-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import { goToError } from '@shared/mixins';
import { DataLayer } from '@shared/services/dataLayer';

export default {
  name: 'CwBasicInformation',

  components: {
    'validation-observer': ValidationObserver,
    'cw-text-field': () => import('@shared/components/inputs/CwTextField'),
    'cw-checkbox': () => import('@shared/components/inputs/CwCheckbox'),
    'cw-pep': () => import('@fi/pages/steps/ConfirmationPEP'),
  },

  mixins: [goToError],

  data: () => ({
    form: {
      email: '',
      marketingAllowed: '',
      ringData: '',
      confirmCode: '',
      politicallyExposedPerson: 'off',
    },
    workflow: '',
  }),

  computed: {
    ...mapGetters({
      initialData: 'application/getInitialData',
    }),

    /**
     * Gettext translations
     */
    confirmCodeHint: vm => vm.$gettext('Enter the verification code you received via SMS'),
    confirmCodeLabel: vm => vm.$gettext('Verification code'),
    emailHint: vm => vm.$gettext('Email in the form of name@example.com'),
    emailLabel: vm => vm.$gettext('Email'),
    marketingAllowedLabel: vm => vm.$gettext('I want to receive marketing messages'),
  },

  async created() {
    // workflow likely empty at this point
    let wf = this.getStateWorkflow();
    if (!this.isBrokered() && !this.isLender()) {
      await this.pollForWorkflow(20);
      wf = this.getStateWorkflow();
    }
    this.workflow = wf;
  },

  methods: {
    ...mapActions({
      submitForm: 'application/submit',
      executeRoutine: 'application/executeRoutine',
    }),
    ...mapGetters({
      isBrokered: 'application/isBrokered',
      isLender: 'application/isLender',
      getStateWorkflow: 'application/getStateWorkflow',
    }),

    async pollForWorkflow(maxIterations) {
      let max = maxIterations;
      const wait = time => new Promise(resolve => setTimeout(resolve, time));
      const poll = async (time) => {
        if (this.isBrokered() || this.isLender()) {
          // got result
          return this.getStateWorkflow();
        }

        if (max === 0) {
          // TODO: time has passed. what to do?
          console.log('got no result, defaulting to lender');
          return 'lender';
        }

        await wait(time);
        max -= 1;
        return poll(time);
      };
      return poll(100);
    },

    showPep() {
      return this.isBrokered();
    },

    /**
     * Reset the backup code
     */
    async retryStart() {
      this.$wait.start('SUBMIT_FORM');

      const routineParams = {
        uri: 'executeRoutine',
        routine: 'Retry Start',
      };

      try {
        await this.executeRoutine(routineParams);
        this.$wait.end('SUBMIT_FORM');
      } catch (e) {
        this.$wait.end('SUBMIT_FORM');
      }
    },

    async validateForm() {
      // make sure pep is not set if workflow is lender
      await this.$nextTick();
      const valid = await this.$refs.observer.validate();
      this.$dataLayer.PushToECommerce({
        params: {
          action: 'stepValidationClick',
        },
      });

      DataLayer.onValidationClick();

      if (!valid) {
        this.goToError();
        DataLayer.onValidationFail();

        return;
      }

      this.$dataLayer.PushToECommerce({
        params: {
          action: 'stepValidationSubmit',
        },
      });
      await this.submit();
    },

    async submit() {
      this.$wait.start('SUBMIT_FORM');

      const formParams = { ...this.form };
      const routineParams = {
        uri: 'executeRoutine',
        routine: 'Basic Information',
      };

      try {
        await this.submitForm({
          formParams,
          routineParams,
        });
      } catch (e) {
        this.$wait.end('SUBMIT_FORM');
      }
    },
  },
};
</script>
